import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useConfig from '../useConfig';
import {DisplayMode} from "../../constants/display-mode";
import {ROUTE_NAMES} from "../../router/route-names";

export default function useNavigationHandler() {
    const navigate = useNavigate();
    const { name, displayMode } = useConfig();

    const handleGoHomeButton = useCallback(() => {
        if (displayMode === DisplayMode.Search) {
            navigate(`../${ROUTE_NAMES.selectEvse}`);
        } else {
            if (window.location.pathname.includes('tenant')) {
                navigate(`../${ROUTE_NAMES.selectEvse}`);
            } else {
                navigate(`../tenant/${name}/${ROUTE_NAMES.selectEvse}`);
            }
        }
    }, [navigate, displayMode, name]);

    return {
        handleGoHomeButton
    };
}
