import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../../components/Header';
import ChargePointContainer from '../../components/ChargePointContainer';
import Evse from '../../components/Evse';
import Loader from '../../components/Loader';
import DownloadApps from '../../components/DownloadApps';
import Logo from '../../components/Logo';

import { Evse as EvseModel, HardwareStatus } from '../../models/evse';
import useChargePointData from '../../hooks/useChargePointData';
import useConfig from '../../hooks/useConfig';
import { DisplayMode } from '../../constants/display-mode';
import { ROUTE_NAMES } from '../../router/route-names';
import ReceiptsUrl from '../../components/ReceiptsUrl/ReceiptsUrl';

const SelectEvse = () => {
  const { t } = useTranslation();
  const { chargePoint, evses, tenant, availableCount, error } = useChargePointData();
  const { appStoreUrl, receiptsUrl, googleStoreUrl, logoUrl, displayMode, languages } = useConfig();
  const navigate = useNavigate();

  const startSessionIndex = window.location.pathname
    .split('/')
    .findIndex(element => element === ROUTE_NAMES.sessionStart);

  if (!chargePoint || !evses) {
    if (!error) {
      return <Loader />;
    }

    return (
      <section className="page-container">
        <section className={'general-error'}>{t('app.charge_point.missing.details.error')}</section>
      </section>
    );
  }

  const handleBackClick = () => {
    let path = window.location.pathname.split('/cp')[0];

    if (displayMode === DisplayMode.Search) {
      if (startSessionIndex > 0) {
        path += `/${ROUTE_NAMES.chooseLanguage}`;
      } else {
        path += `/${ROUTE_NAMES.search}`;
      }
    } else if (displayMode === DisplayMode.ChargePoint) {
      path += `/${ROUTE_NAMES.chargePoint}`;
    } else {
      path += `/${ROUTE_NAMES.chooseLanguage}`;
    }

    navigate(path);
  };

  return (
    <section className="page-container">
      <Header
        showBackButton={languages && languages?.length ? true : displayMode !== DisplayMode.Evse ? true : false}
        label={t('app.select_evse.header')}
        onClick={handleBackClick}
      />
      {logoUrl && <Logo logoUrl={logoUrl} />}
      {displayMode === DisplayMode.ChargePoint && (
        <ChargePointContainer
          name={chargePoint.name}
          location={chargePoint.address}
          numberOfEvses={availableCount}
          status={HardwareStatus.available}
        />
      )}

      {!evses?.length && (
        <section className={'general-error'}>{t('app.select_evse.missing.available.evses.error')}</section>
      )}

      {evses.map((evse: EvseModel) => (
        <Evse key={evse.id} evse={evse} chargePointId={chargePoint.id} numberOfEvses={evses.length} tenant={tenant} />
      ))}

      {evses.length === 1 && (!!appStoreUrl || !!googleStoreUrl) && (
        <DownloadApps appStoreUrl={appStoreUrl} googleStoreUrl={googleStoreUrl} />
      )}

      {receiptsUrl && <ReceiptsUrl />}
    </section>
  );
};

export default SelectEvse;
