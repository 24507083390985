import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Loader from './components/Loader';

import { ENDPOINT_URL } from './constants/endpoints';
import { handleAndMonitorError, setConfigurationData } from './helpers';
import { Http } from './http';
import { addTranslation, defaultLanguageCode } from './i18n';
import { ROUTE_NAMES } from './router/route-names';
import { CONFIG_KEY, LANGUAGE_KEY, LocalStorage } from './storage';
import i18n from 'i18next';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const language = LocalStorage.getItem(LANGUAGE_KEY);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await new Http().get(ENDPOINT_URL.config);
        setConfigurationData(response?.data?.data);
        await addTranslation((language as string) ?? defaultLanguageCode);
        await i18n.changeLanguage(language as string);
        const refreshUrl = response.headers['refresh-url'] ?? '';
        if (refreshUrl) {
          navigate(refreshUrl);
        } else {
          navigate(`./tenant/${response.headers['tenant']}/${ROUTE_NAMES.chooseLanguage}`, {
            state: {
              displayMode: response?.data?.data?.displayMode
            }
          });
        }
        setLoading(false);
      } catch (e) {
        handleAndMonitorError(
          `Error while trying to load configuration from ${ENDPOINT_URL.config} and translations for ${defaultLanguageCode} language code: ${e}`
        );
        setError('Error while trying to load configuration and translations');
        setLoading(false);
      }
    })();

    return () => {
      LocalStorage.removeItem(CONFIG_KEY);
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <>{error}</>;
  }

  return <Outlet />;
};

export default App;
