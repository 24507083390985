import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import PinCode from '../../components/PinCode';
import TextButton from '../../components/TextButton';

import { ROUTE_NAMES } from '../../router/route-names';
import { checkPinCode } from '../../helpers';

import styles from './styles.module.css';
import { Payment } from '../../models/payment';
import { LocalStorage, PAYMENT_KEY } from '../../storage';
import useConfig from '../../hooks/useConfig';
import { DisplayMode } from '../../constants/display-mode';
import { StopSessionMode } from '../../models/config';
import EnterLast4 from '../EnterLast4';

const EnterPinCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let { name, displayMode, stopSessionMode } = useConfig();
  const [error, setError] = useState<string | null>(location?.state?.error);
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;

  const handleEnterPinCode = (pinCode: string) => {
    const validationResult = checkPinCode(pinCode, t);

    if (validationResult.isInvalid) {
      setError(validationResult.errorMessage);
      return;
    }

    setError(null);

    if (location?.state?.summary) {
      navigate(`../${ROUTE_NAMES.chargingSession}/${payment?.sessionId}/${ROUTE_NAMES.sessionSummary}`, {
        state: {
          pin: pinCode
        },
        replace: true
      });
      return;
    }

    navigate(`../${ROUTE_NAMES.chargingSession}/${payment?.sessionId}`, {
      state: {
        pin: pinCode,
        enterPin: true,
        sessionId: payment?.sessionId
      },
      replace: true
    });
  };

  const handleBackButton = () => {
    if (displayMode === DisplayMode.Search) {
      navigate(`../${ROUTE_NAMES.selectEvse}`);
    } else {
      if (window.location.pathname.includes('tenant')) {
        navigate(`../${ROUTE_NAMES.selectEvse}`);
      } else {
        navigate(`../tenant/${name}/${ROUTE_NAMES.selectEvse}`);
      }
    }
  };

  return stopSessionMode === StopSessionMode.PIN_CODE ? (
    <section className="page-container">
      <PinCode
        showBackButton
        headerLabel={t('app.enter_pin_code.header')}
        buttonLabel={t('app.enter_pin_code.proceed.button')}
        onBackClick={handleBackButton}
        onForwardClick={handleEnterPinCode}
        error={error}
      />
      <section className={styles.button}>
        <TextButton
          label={t('app.enter_pin_code.forgot.button')}
          onClick={() => navigate(`../${ROUTE_NAMES.reEnterCardDetails}`)}
        />
      </section>
    </section>
  ) : (
    <EnterLast4 />
  );
};

export default EnterPinCode;
