import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from '../../../router/route-names';
import { LocalStorage, PAYMENT_KEY } from '../../../storage';
import Loader from '../../../components/Loader';
import { ENDPOINT_URL } from '../../../constants/endpoints';
import { handleAndMonitorError } from '../../../helpers';
import usePayment from '../../../hooks/usePayment/index';
import { Payment } from '../../../models/payment';
import useChargePointData from '../../../hooks/useChargePointData';
import useConfig from '../../../hooks/useConfig';
import { SessionStatus } from '../../../models/session';
import useSession from '../../../hooks/useSession';
import useNavigationHandler from '../../../hooks/useNavigationHandler';

const ConfirmWorldLinePayment = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { getPaymentStatus } = usePayment();
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;
  const { chargePoint, evses } = useChargePointData();
  const { stopSessionMode } = useConfig();
  const { startSession } = useSession();
  const { handleGoHomeButton } = useNavigationHandler();

  useEffect(() => {
    let isMounted = true;

    const handleBeforeUnload = event => {
      // Perform actions before the component unloads
      event.preventDefault();

      //console.log('handleBeforeUnload');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    let finishing = false;

    const getPayment = async () => {
      if (!isMounted || finishing || !payment?.paymentIntentId || !chargePoint || !evses) return;

      try {
        const { data } = await getPaymentStatus(payment.paymentIntentId);

        if (data.status) {
          if (data.status === "AUTHORIZED") {
            finishing = true;
            await handleConfirmPayment();
          } else if (data.status !== "CREATED") {
            finishing = true;
            handleRejectPayment();
          }
        }
      } catch (e: any) {
        finishing = true;

        handleAndMonitorError(
          `Error while trying to get WL order status ${ENDPOINT_URL.getPaymentStatus(payment.paymentIntentId)}: ${e?.response?.data?.error}`
        );
        setErrorMessage(`${e?.response?.data?.error}`);
        handleRejectPayment();
      }
    };

    const interval = setInterval(getPayment, 2000);

    return () => {
      clearInterval(interval);
      isMounted = false;
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [payment, chargePoint, evses]);

  useEffect(() => {
    if (!payment?.paymentIntentId) {
      handleGoHomeButton();
    }
  }, [payment]);

  const handleConfirmPayment = useCallback(async () => {
    if (payment) {
      if (stopSessionMode === 'last_4_digits') {
        try {
          const response = await startSession({
            chargePointId: payment.chargePointId,
            evseId: payment.evseId,
            pin: '', // It'll be set from the backend
            paymentIntentId: payment.paymentIntentId,
            depositAmount: payment.amount,
            currency: payment.currency,
            connectorId: payment.connectorId
          });

          let sessionId = response?.data?.sessionId;
          let pin = response?.data?.pin;

          if (sessionId && pin) {
            navigate(`../${ROUTE_NAMES.chargingSession}/${sessionId}`, {
              state: {
                pin,
                sessionId
              },
              replace: true
            });
          }
        } catch (e) {
          handleAndMonitorError(`Error while trying to startSession: ${e}`);
          navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
            state: {
              status: SessionStatus.failed,
              title: JSON.parse(e.response.data.error).localeTitleKey,
              description: JSON.parse(e.response.data.error).localeDescrKey
            },
            replace: true
          });
        }
      } else {
        navigate(`../${ROUTE_NAMES.choosePaymentMethod}/${payment.paymentIntentId}/${ROUTE_NAMES.howToSetPinCode}`, {
          state: {
            paymentIntentId: payment.paymentIntentId
          }
        });
      }

      return;
    }
  }, [navigate, payment, startSession, stopSessionMode]);

  const handleRejectPayment = useCallback(() => {
    if (payment && chargePoint && evses) {
      LocalStorage.removeItem(PAYMENT_KEY);

      const slicePoint = window.location.href.indexOf('/confirm-payment');
      const newUrl = window.location.href.slice(0, slicePoint);
      const returnUrl = `${newUrl}/evses`;
      window.location.replace(returnUrl);
    }
  }, [chargePoint, evses, payment]);

  return (
    <section>
      {!errorMessage ? (
        <Loader />
      ) : (
        <section className={'general-error'}>{errorMessage && <div>{errorMessage}</div>}</section>
      )}
    </section>
  );
};
export default ConfirmWorldLinePayment;
