import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Header from '../../components/Header';
import Button, { ButtonType } from '../../components/Button';
import ChargePointContainer from '../../components/ChargePointContainer';
import Loader from '../../components/Loader';
import DownloadApps from '../../components/DownloadApps';

// Constants and models
import { ROUTE_NAMES } from '../../router/route-names';
import { FA_ICONS } from '../../constants';
import { HardwareStatus } from '../../models/evse';

// Hooks
import useChargePointData from '../../hooks/useChargePointData';
import useConfig from '../../hooks/useConfig';

import styles from './styles.module.css';
import ReceiptsUrl from '../../components/ReceiptsUrl/ReceiptsUrl';
import { DisplayMode } from '../../constants/display-mode';

const ChargePoint = () => {
  const { t } = useTranslation();
  const { chargePoint, availableCount, evses, tenant, error } = useChargePointData();
  const { languages, appStoreUrl, googleStoreUrl, receiptsUrl, displayMode } = useConfig();
  const navigate = useNavigate();

  if (!chargePoint) {
    if (!error) {
      return <Loader />;
    }

    return (
      <section className="page-container">
        <section className={'general-error'}>{t('app.charge_point.missing.details.error')}</section>
      </section>
    );
  }

  if (evses.length === 1) {
    navigate(`../tenant/${tenant}/cp/${chargePoint.id}/${ROUTE_NAMES.selectEvse}`);
    return;
  }

  return (
    <section className="page-container">
      <Header
        showBackButton={languages && languages?.length ? true : false}
        label={t('app.charge_point.header')}
        onClick={() => navigate(`../tenant/${tenant}/${ROUTE_NAMES.chooseLanguage}`)}
      />
      <main>
        <ChargePointContainer
          name={chargePoint.name}
          location={chargePoint.address}
          numberOfEvses={availableCount}
          status={HardwareStatus.available}
        />
        <hr className={styles.line} />
        <Button
          label={t('app.charge_point.continue.button')}
          type={ButtonType.primary}
          icon={FA_ICONS.faAngleRight}
          onClick={() => navigate(`../tenant/${tenant}/cp/${chargePoint.id}/${ROUTE_NAMES.selectEvse}`)}
        />
        {(!!appStoreUrl || !!googleStoreUrl) && (
          <>
            <hr className={styles.line} />
            <section className={styles.bold}>{t('app.charge_point.or.separator.label')}</section>
            <hr className={styles.line} />
            <DownloadApps appStoreUrl={appStoreUrl} googleStoreUrl={googleStoreUrl} />
          </>
        )}
        {receiptsUrl && displayMode === DisplayMode.ChargePoint && <ReceiptsUrl />}
      </main>
    </section>
  );
};

export default ChargePoint;
