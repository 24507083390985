import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { ROUTE_NAMES } from '../../router/route-names';
import { checkLast4, handleAndMonitorError } from '../../helpers';

// Types
import { Payment } from '../../models/payment';
import { DisplayMode } from '../../constants/display-mode';

import styles from './styles.module.css';

import useConfig from '../../hooks/useConfig';
import useSession from '../../hooks/useSession';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Button, { ButtonType } from '../../components/Button';

import { LocalStorage, PAYMENT_KEY } from '../../storage';
import { ENDPOINT_URL } from '../../constants/endpoints';

const EnterLast4 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { name, displayMode } = useConfig();
  const [cardDigits, setCardDigits] = useState<string>('');
  const [cardDigitsError, setCardDigitsError] = useState<string | null>(location?.state?.error);
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;
  const { getSession } = useSession();
  const [disableContinue, setDisableContinue] = useState(false);

  const onNext = async () => {
    setDisableContinue(true);

    const result = checkLast4(cardDigits, t);

    if (result.isInvalid) {
      setCardDigitsError(result.errorMessage);

      setDisableContinue(false);

      return;
    }

    if (!payment) {
      setCardDigitsError('No available payment');

      setDisableContinue(false);

      return;
    }

    if (location?.state?.summary) {
      navigate(`../${ROUTE_NAMES.chargingSession}/${payment?.sessionId}/${ROUTE_NAMES.sessionSummary}`, {
        state: {
          pin: cardDigits
        },
        replace: true
      });

      setDisableContinue(false);

      return;
    }

    try {
      await getSession(payment.chargePointId, payment.evseId, payment?.sessionId ?? '', cardDigits);

      navigate(`../${ROUTE_NAMES.chargingSession}/${payment?.sessionId}`, {
        state: {
          pin: cardDigits,
          enterPin: true,
          sessionId: payment?.sessionId
        },
        replace: true
      });
    } catch (e) {
      handleAndMonitorError(
        `Error while trying to get session data from ${ENDPOINT_URL.session(
          payment.chargePointId,
          payment.evseId,
          payment?.sessionId,
          cardDigits
        )}: ${e}`
      );
      setCardDigitsError('Incorrect last 4 digits.');
    } finally {
      setDisableContinue(false);
    }
  };

  const onBack = () => {
    if (displayMode === DisplayMode.Search) {
      navigate(`../${ROUTE_NAMES.selectEvse}`);
    } else {
      if (window.location.pathname.includes('tenant')) {
        navigate(`../${ROUTE_NAMES.selectEvse}`);
      } else {
        navigate(`../tenant/${name}/${ROUTE_NAMES.selectEvse}`);
      }
    }
  };

  return (
    <section className="page-container">
      <Header showBackButton label={t('app.last_4_digits.title')} onClick={onBack} />
      <section className={styles.container}>
        <Input
          label={t('app.re_enter_card_details.input.card.details.label')}
          type="number"
          error={cardDigitsError}
          setValue={setCardDigits}
        />
      </section>
      <Button
        label={t('app.last_4_digits.submit.button')}
        type={ButtonType.primary}
        onClick={onNext}
        disabled={disableContinue}
      />
    </section>
  );
};

export default EnterLast4;
