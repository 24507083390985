export interface Config {
  name: string | undefined;
  displayName: string | undefined;
  displayMode: string | undefined;
  colorPrimary: string | undefined;
  colorSecondary: string | undefined;
  paymentProvider: string | undefined;
  searchModeTextHint?: string;
  stripeKey: string | undefined;
  stripeSecret: string | undefined;
  stopSessionMode: string;
  worldLineGroupCards?: boolean;
  merchantId?: string;
  termsAndConditionsUrl: string | undefined;
  termsAndConditionsChecked: boolean | undefined;
  contactPhoneNumber: string | undefined;
  receiptsUrl: string | undefined;
  logoUrl: string | undefined;
  languages: string[] | undefined;
  localizeSuffix: string | undefined;
  appStoreUrl: string | undefined;
  googleStoreUrl: string | undefined;
}

export enum StopSessionMode {
  PIN_CODE = 'pin_code',
  LAST_4_DIGITS = 'last_4_digits'
}
